body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.absolute-center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-wrapper {
  width: 100%;
  max-width: 350px;
}

.form {
  margin: 2rem;
}
