.page-container {
  max-width: 640px;
  margin: 2rem auto;
  padding: 0px 2rem;
}

.card {
  background-color: #f4f5f7;
  box-shadow: rgba(9, 30, 66, 0.2) 0px 1px 1px, rgba(9, 30, 66, 0.24) 0px 0px 1px 0px;
  border-radius: 3px;
  padding: 1rem;
  margin: 0.5rem 0;
}

.pay-btn-wrapper {
  margin-bottom: 2rem;
  text-align: center;
}

.text-center {
  text-align: center !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.absolute-center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.form-wrapper {
  width: 100%;
  max-width: 350px;
}

.form {
  margin: 2rem;
}

